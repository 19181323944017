import React from 'react';
import classNames from 'classnames';

import css from './Topbar.module.css';

/**
 * Menu icon (hamburger icon)
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to components own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @returns {JSX.Element} menu icon
 */
const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg
      className={classes}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="12" width="36" height="2" />
      <rect x="12" y="22" width="24" height="2" />
      <rect x="6" y="32" width="36" height="2" />
    </svg>
  );
};

export default MenuIcon;
